/* 公開前 */
.border-before-publish {
    border-color: #DED796;
}

.bg-before-publish-title {
    background-color: #DED796;
}

.bg-before-publish-card {
    background-color: #FFFEF5;
}

/* 公開後 */
.border-publishing {
    border-color: #72BAD9;
}

.bg-publishing-title {
    background-color: #72BAD9;
}

.bg-publishing-card {
    background-color: #F2FBFF;
}

/* 配送中 */
.border-delivery {
    border-color: #DE8383;
}

.bg-delivery-title {
    background-color: #DE8383;
}

.bg-delivery-card {
    background-color: #FFE3E3;
}

/* 終了 */
.border-done {
    border-color: #919191;
}

.bg-done-title {
    background-color: #919191;
}

.bg-done-card {
    background-color: #EAEAEA;
}